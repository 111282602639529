
html,body {
	width: 100%;
	margin: 0;

}
body{
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#0c1019+0,0b0b0e+100 */
  /*background: rgb(12,16,25); /* Old browsers */
  /*background: -moz-radial-gradient(center, ellipse cover,  rgba(12,16,25,1) 0%, rgba(11,11,14,1) 100%); /* FF3.6-15 */
  /*background: -webkit-radial-gradient(center, ellipse cover,  rgba(12,16,25,1) 0%,rgba(11,11,14,1) 100%); /* Chrome10-25,Safari5.1-6 */
  /*background: radial-gradient(ellipse at center,  rgba(12,16,25,1) 0%,rgba(11,11,14,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  /*filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0c1019', endColorstr='#0b0b0e',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
  
  background-image: url("./pictures/pic3.jpg");
  background-position: center center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}



@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");

@font-face {
    font-family: pirulen;
    src: url(https://raw.githubusercontent.com/th3knigh7/css-design/black-bg/pirulen.ttf);
	
	}

	@font-face {
		font-family: Cyber;
		src: url("https://assets.codepen.io/605876/Blender-Pro-Bold.otf");
		font-display: swap;
	  }


/* --------------------------------------------------------------------SIGNEDINLINKS----------------------------------------------------------------------------------*/
	
	
	nav li a{
		color:rgb(247, 243, 243);
		text-decoration: none;
		transition: letter-spacing .5s;
		font-size: 11px;
		font-family: pirulen;
	}

	@media screen and (max-width:540px) {
		nav li a{
			font-size: 0px;
		}
	}
	
	footer{
		position: fixed;
		color: whitesmoke;
		font-family: pirulen;
		font-size: small;
		left: 50%;
		bottom: 0%;
		transform: translate(-50%, -50%);
		cursor: default;
	}

	@media screen and (max-width:540px) {
		footer{
			font-size: 10px;
		}
	}
	.circle_main{
		/*font-family: pirulen;*/
		font-size:10px;
		color:white;
		position: relative;
		margin:0 auto;
		width:100px;
		height:100px;
		border-radius: 50%;
		 /* Old browsers */
 		/*background: -moz-radial-gradient(center, ellipse cover,  rgba(12,16,25,1) 0%, rgba(11,11,14,1) 100%); /* FF3.6-15 */
  		/*background: -webkit-radial-gradient(center, ellipse cover,  rgba(12,16,25,1) 0%,rgba(11,11,14,1) 100%); /* Chrome10-25,Safari5.1-6 */
  		/*background: radial-gradient(ellipse at center,  rgba(12,16,25,1) 0%,rgba(11,11,14,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
 		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0c1019', endColorstr='#0b0b0e',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
		transition: width 1s ,height 1s;
		z-index: 1;
		animation: change_circle_opacity 5s;
	}

	@media screen and (max-width: 540px){
		.circle_main{
			width:80px;
			height:80px;
			margin-top: 10px;
		}
	}

	@keyframes change_circle_opacity{
		0%{
			opacity: 0.5;
		}
		100%{
			opacity: 1;
		}
	}
	
	
	.change .circle_main{
    width:110px;
	height:110px;
	}
	
	@media screen and (max-width: 540px){
		.change .circle_main{
			width:90px;
			height:90px;
			margin-top: 10px;
		}
	}
	.bar_left{
		position:absolute;
		top:35px;
		right:43px;
		width: 4px;
		height: 33px;
		border-radius:2px;
		box-shadow: 0 0 13px 3px rgb(85, 3, 3);
		transition:transform 1s, width 1s;
		z-index:-2;
	}
	@media screen and (max-width: 540px){
		.bar_left{
			top:30px;
			height: 27px;
		}
	}
	
	.change .bar_left{
	width:275px;
	transform: skewX(20deg);
	background-color: #000;
	animation: op 2s;	
	}
	@media screen and (max-width: 540px){
		.change .bar_left{
			width:175px;
		}
	}
	
	.bar_right{
		position:absolute;
		top:35px;
		left:43px;
		width: 4px;
		height: 33px;
		border-radius:2px;
		box-shadow: 0 0 13px 3px rgb(85, 3, 3);
		transition: transform 1s,width 1s;
		z-index: -1;
	}
	@media screen and (max-width: 540px){
		.bar_right{
			top:30px;
			height: 27px;
		}
	}
	.change .bar_right{	
	width:275px;
	transform: skewX(-20deg);
	background-color: #000;
	animation: op 2s;
	}
	
	@media screen and (max-width: 540px){
		.change .bar_right{
			width:175px;
		}
	}
	@keyframes op{
		0%{opacity:0;}
		20%{opacity:0;}
		40%{opacity:0;}
		100%{opacity:1;}
	}
	
.arc1 {
  content:"";
  position: absolute;
  width: 90%;
  height: 90%;
  left: 4.92%;
  top: 4.92%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  animation: rotate 4s linear infinite;
  text-align: center;
  line-height: 129px;
}
	
.line1 {
  border: 2px solid white ;
  border-left: 1px solid transparent;
  animation: rotate 5s linear infinite;
}

.line2 {
  border: 4px solid white;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  animation: rotate_anti 4s linear infinite;
}

.line3 {
  border: 2px solid white;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
  animation: rotate 3s linear infinite;
}


		
.reactor {
  position:absolute;
  background:black;
  width: 30%;
  height: 30%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  border: 5px solid #050505;
  margin:30%;
  cursor: pointer;
}


.reactor_log {
	position:absolute;
	background:#fff;
	width: 30%;
	height: 30%;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	border-radius: 50%;
	border: 5px solid rgba(2, 255, 255, 0.15);
	margin:30%;
  }


.change .reactor{
		 animation: flicker .6s infinite;
	}
	
.reacttext{
		opacity:0;
		position:absolute;
		top:25%;
		left: 18%;
		margin:0 auto;
		color:white;
		font-size:14px;
		font-weight: 900;
		font-family:purilen;		
		transition: opacity 1s;
		}

		@media screen and (max-width: 540px){
			.reacttext{
				top:25%;
				left: 18%;
				font-size:12px;
			}
		}

.reacttext_log{
		opacity:1;
		position:absolute;
		top:10%;
		left: 21%;
		margin:0 auto;
		color:rgba(0,0,0,1);		
		transition: opacity 1s;
		}

.change .reacttext{
	opacity:1;
}
	
	
	/* Navigation css*/
	
	.bar_left>ul{
		padding: 0;
		margin:10px 70px 0 0px;
		list-style:none;
		transform: skewX(-20deg);
	}
	
	.bar_right>ul{
		padding:0;
		margin:10px 0 0 70px;
		list-style:none;
		transform: skewX(20deg);
		}
	
	li{margin:auto;
	}

	nav li a:hover{
		letter-spacing:2px;
	}

	@media screen and (max-width:540px){
		nav li a:hover{
			letter-spacing:0px;
		}
	}
	
	.nav_top{
		display:none;
		flex-direction: row;
	}
	
	.change .nav_top{display: flex;}
	
	.nav_bottom{
		display:none;
		flex-direction:row-reverse;
	}
	.change .nav_bottom{display: flex;}

		
	/* ham style */
	
	.hamContainer{
		position: absolute;
		top:-6%;
		transition:opacity 1s;

	}
	@media screen and (max-width:540px){
		.hamContainer{
			top:-20%;
			left: -10%;
		}
	}
	
	.hbar1, .hbar2, .hbar3,.hbar4,.hbar5 {
    height: 2px;
    background-color:white;
	border-radius: 4px;
	margin:4px auto;
	}

 
	.hbar1 {
	width:15px;
	animation: a 1s infinite;
	animation-delay: .5s;	
	}

	.hbar2 {
	width:20px;
	animation:a 1s infinite;
	animation-delay: .5s;}

	.hbar3 {
	width: 30px;
	animation:a 1s infinite;
	animation-delay: .5s;
	}

	.hbar4 {
	width:20px;
	animation:a 1s infinite;
	animation-delay: .5s;
	}

	.hbar5 {
	width:15px;
	animation:a 1s infinite;
	animation-delay: .5s;
	}
	
	.change .hamContainer{
		opacity:0;
	}

	


@keyframes a {
    0% {
        
        transform: scaleX(1) skewX(10deg)  ;
    }
    50% {
        
        transform: scaleX(.7) skewX(20deg)  ;
    }
    100% {
        
        transform: scaleX(1) skewX(30deg);
    }
}

	
	/*ham style ends*/
	
	
	/*Animations*/
	
		@keyframes flicker{
  0% {
    box-shadow: 0px 0px 0px 0px , inset 0px 1px 4px 2px rgb(85, 3, 3);
  }
  40% {
    box-shadow: 0px 0px 0px 0px , inset 0px 1px 4px 2px rgb(85, 3, 3);
  }
  50% {
    box-shadow: 0px 0px 0px 0px , inset 0px 1px 100px 2px rgb(85, 3, 3);
  }
  60% {
    box-shadow: 0px 0px 0px 0px , inset 0px 1px 4px 2px rgb(85, 3, 3);
  }
  100% {
    box-shadow: 0px 0px 0px 0px , inset 0px 1px 4px 2px rgb(85, 3, 3);
  }
}
	
	
@keyframes rotate {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}
	
	
@keyframes rotate_anti {
  0% {
    transform: rotateZ(360deg);
  }
  100% {
    transform: rotateZ(0deg);
  }
}


 


/* --------------------------------------------------------------------Login----------------------------------------------------------------------------------*/
form{	
  text-align: center;
  position: fixed;
  top: 45%;	
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #080000;
  /*background-image: linear-gradient(to right top, #000000, #170508, #24090d, #300b0d, #3d0b0b);*/
  /*background-image: linear-gradient(to right top, #000000, #130307, #1e070b, #270a0d, #300b0d, #320b12, #330b17, #340b1b, #290e21, #1e1122, #14111f, #0c1019);*/
  width: 450px;
  height: 390px;
  border-radius: 30px;
  border: 2px black solid;
  opacity: 0.98;
}

@media screen and (max-width: 540px){
	form{
		width: 250px;
  		height: 390px;
	}
}

/*@media screen and (min-width: 540px) and (max-width: 780px) {
    .view {
        width: 600px;
    }
}*/

.form-text{
	text-align: center;
	font-family: pirulen;
	color:white;
	margin: 10px;
  }

  @keyframes fade {
	0% {
	  opacity: 1;
	}
	
	50% {
	  opacity: 0;
	}
	
	100% {
	  opacity: 1;
	}
  }

.loading-text{
  text-align: center;
  position: fixed;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: pirulen;
  color:white;
  animation: fade 2s ease 0s infinite;
}



.loader {
  text-align: center;
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 175px;
  height: 100px;
} 
  .loader_span {
    display: block;
    background-color: #ccc;
    width: 7px;
    height: 10%;
    border-radius: 14px;
    margin-right: 5px;
    float: left;
    margin-top: 25%;
  }	
  .loader_span:last-child {
      margin-right: 0px;
    }
    .loader_span:nth-child(1) {
      animation: load 2.5s 1.4s infinite linear;
    }
    .loader_span:nth-child(2) {
      animation: load 2.5s 1.2s infinite linear;
    }
    .loader_span:nth-child(3) {
      animation: load 2.5s 1s infinite linear;
    }
    .loader_span:nth-child(4) {
      animation: load 2.5s 0.8s infinite linear;
    }
    .loader_span:nth-child(5) {
      animation: load 2.5s 0.6s infinite linear;
    }
    .loader_span:nth-child(6) {
      animation: load 2.5s 0.4s infinite linear;
    }
    .loader_span:nth-child(7) {
      animation: load 2.5s 0.2s infinite linear;
    }
    .loader_span:nth-child(8) {
      animation: load 2.5s 0s infinite linear;
    }
    .loader_span:nth-child(9) {
      animation: load 2.5s 0.2s infinite linear;
    }
    .loader_span:nth-child(10) {
      animation: load 2.5s 0.4s infinite linear;
    }
    .loader_span:nth-child(11) {
      animation: load 2.5s 0.6s infinite linear;
    }
    .loader_span:nth-child(12) {
      animation: load 2.5s 0.8s infinite linear;
    }
    .loader_span:nth-child(13) {
      animation: load 2.5s 1s infinite linear;
    }
    .loader_span:nth-child(14) {
      animation: load 2.5s 1.2s infinite linear;
    }
    .loader_span:nth-child(15) {
      animation: load 2.5s 1.4s infinite linear;
    }


@keyframes load {
  0% {
    background: #f7d4d4;
    margin-top: 25%;
    height: 10%;
  }
  50% {
    background: #3d0b0b;
    height: 100%;
    margin-top: 0%;
  }
  100% {
    background: #f7d4d4;
    height: 10%;
    margin-top: 25%;
  }
}




#log_lock{
	margin-top: 5px;
	font-size: 80px;
	color:#3d0b0b;
	margin-right: 5px;
}


  
label{
  display: block;
  letter-spacing: 4px;
  padding-top: 30px;
  text-align: center;
} 

.label-text{
	color: whitesmoke;
	font-family: pirulen;
    cursor: text;
    font-size: 10px;
    line-height: 20px;
    text-transform: uppercase;
    transform : translateY(-34px);
    transition: all 0.3s;
}
    
  input{
    background-color: transparent;
    border: 0;
    border-bottom: 2px solid whitesmoke;
    color: whitesmoke;
    font-size: 20px;
    letter-spacing: -1px;
    outline: 0;
    padding: 5px 20px;
    text-align: center;
    transition: all 0.3s;
    width: 40%;
  }

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: whitesmoke;
  -webkit-box-shadow: 0 0 0px 1000px #080000 inset;
  transition: background-color 5000s ease-in-out 0s;
}

 input:focus{ 
    max-width: 100%;
    width: 50%;
	border-bottom: 2px solid #3d0b0b;
}
    
	input:focus  ~.label-text{
        font-size: 11px;
		font-weight: bold;
        transform:translateY(-70px);
		margin-top: 5px;
	}
      
    input:valid ~.label-text{
      font-size: 11px;
	  font-weight: bold;
      transform:translateY(-70px);
	}
      
/*-----------------------------------------------------------------animated button login-----------------------------------------------------------------------------*/
.animated-button1 {
	background: linear-gradient(-30deg, #3d0b0b 50%, #2b0808 50%);
	width: 40%;
	padding: 20px 40px;
	border: 0;
	font-family: pirulen;
	display: inline-block;
	-webkit-transform: translate(0%, 0%);
			transform: translate(0%, 0%);
	overflow: hidden;
	color: whitesmoke;
	margin-top: 10px;
	font-size: 15px;
	letter-spacing: 2.5px;
	text-align: center;
	cursor: pointer;
	text-decoration: none;
	-webkit-box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
			box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
	margin-bottom: 10px;		
  }

  @media screen and (max-width: 540px){
	  .animated-button1{
		width: 80%;
		font-size: 10px;
	  }
  }
  
  .animated-button1::before {
	content: '';
	width: 100%;
	height: 100%;
	background-color: #ad8585;
	opacity: 0;
	-webkit-transition: .2s opacity ease-in-out;
	transition: .2s opacity ease-in-out;
  }
  
  .animated-button1:hover::before {
	opacity: 0.2;
  }
  
  .animated-button1 span {
	position: absolute;
  }
  
  .animated-button1 span:nth-child(1) {
	top: 0px;
	left: 0px;
	width: 100%;
	height: 2px;
	background: -webkit-gradient(linear, right top, left top, from(rgba(43, 8, 8, 0)), to(#d92626));
	background: linear-gradient(to left, rgba(43, 8, 8, 0), #d92626);
	-webkit-animation: 2s animateTop linear infinite;
			animation: 2s animateTop linear infinite;
  }
  
  @keyframes animateTop {
	0% {
	  -webkit-transform: translateX(100%);
			  transform: translateX(100%);
	}
	100% {
	  -webkit-transform: translateX(-100%);
			  transform: translateX(-100%);
	}
  }
  
  .animated-button1 span:nth-child(2) {
	top: 0px;
	right: 0px;
	height: 100%;
	width: 2px;
	background: -webkit-gradient(linear, left bottom, left top, from(rgba(43, 8, 8, 0)), to(#d92626));
	background: linear-gradient(to top, rgba(43, 8, 8, 0), #d92626);
	-webkit-animation: 2s animateRight linear -1s infinite;
			animation: 2s animateRight linear -1s infinite;
  }
  
  @keyframes animateRight {
	0% {
	  -webkit-transform: translateY(100%);
			  transform: translateY(100%);
	}
	100% {
	  -webkit-transform: translateY(-100%);
			  transform: translateY(-100%);
	}
  }
  
  .animated-button1 span:nth-child(3) {
	bottom: 0px;
	left: 0px;
	width: 100%;
	height: 2px;
	background: -webkit-gradient(linear, left top, right top, from(rgba(43, 8, 8, 0)), to(#d92626));
	background: linear-gradient(to right, rgba(43, 8, 8, 0), #d92626);
	-webkit-animation: 2s animateBottom linear infinite;
			animation: 2s animateBottom linear infinite;
  }
  
  @keyframes animateBottom {
	0% {
	  -webkit-transform: translateX(-100%);
			  transform: translateX(-100%);
	}
	100% {
	  -webkit-transform: translateX(100%);
			  transform: translateX(100%);
	}
  }
  
  .animated-button1 span:nth-child(4) {
	top: 0px;
	left: 0px;
	height: 100%;
	width: 2px;
	background: -webkit-gradient(linear, left top, left bottom, from(rgba(43, 8, 8, 0)), to(#d92626));
	background: linear-gradient(to bottom, rgba(43, 8, 8, 0), #d92626);
	-webkit-animation: 2s animateLeft linear -1s infinite;
			animation: 2s animateLeft linear -1s infinite;
  }
  
  @keyframes animateLeft {
	0% {
	  -webkit-transform: translateY(-100%);
			  transform: translateY(-100%);
	}
	100% {
	  -webkit-transform: translateY(100%);
			  transform: translateY(100%);
	}
  }
  
/*---------------------------------------------------------------------NAVBAR------------------------------------------------------------------------------------------*/

.navbar{
  text-align: center;
  position: fixed;
  top: 7%;
  left: 50%;
  transform: translate(-50%, -50%);
}


.active {
	font-weight:bold;
}

/*-----------------------------------------------------------------------Home---------------------------------------------------------------------------------------------*/

p{
	color:white;
	padding-left: 23px;
	font-size: medium;
}

li{
	color:white;
}

ul{
	text-align: left;
}

.home-wrapper{
  overflow-x: hidden;
  overflow-y: scroll;
  text-align: left;
  position: fixed;
  top: 15%;
  left: 30%;
  width: 50%;
  height: 60%;
  transform: translate(-50%, 5%);
  cursor: default;
  font-family: Cyber;
  font-size: large;

}

@media screen and (max-width: 540px){
	.home-wrapper{
		width: 60%;
  		height: 73%;
		text-align: left;
		left: 50%;
		transform: translate(-50%, 5%);

	}
}

h2{
	color:#fff;
	text-align: center;
	font-family: pirulen;
	font-size: medium;
}


@media screen and (max-width: 540px){
	.bio, .love, .contact {
		margin-top: 30px;
	}
}
.bio, .love, .contact {
	margin-top: 30px;
}

.style_links {
	color: #fff;
	font-family: pirulen;
	text-transform: uppercase;
	font-size: medium;
	overflow: hidden;
}

.about:hover .style_links span{
	color: rgb(116, 3, 3);
	-webkit-transform: perspective(1000px) rotate3d(0,1,0,360deg);
	transform: perspective(1000px) rotate3d(0,1,0,360deg);
}

.bio:hover .style_links span{
	color: rgb(116, 3, 3);
	-webkit-transform: perspective(1000px) rotate3d(0,1,0,360deg);
	transform: perspective(1000px) rotate3d(0,1,0,360deg);
}

.love:hover .style_links span{
	color: rgb(116, 3, 3);
	-webkit-transform: perspective(1000px) rotate3d(0,1,0,360deg);
	transform: perspective(1000px) rotate3d(0,1,0,360deg);
}

.contact:hover .style_links span{
	color: rgb(116, 3, 3);
	-webkit-transform: perspective(1000px) rotate3d(0,1,0,360deg);
	transform: perspective(1000px) rotate3d(0,1,0,360deg);
}
.style_links span {
	display: inline-block;
	position: relative;
	-webkit-transform: perspective(1000px) rotate3d(0,1,0,0deg);
	transform: perspective(1000px) rotate3d(0,1,0,0deg);
	-webkit-transition: -webkit-transform 0.5s, color 0.5s;
	transition: transform 0.5s, color 0.5s;
	font-size: large;
}

.style_links:hover span {
	color: rgb(116, 3, 3);
	-webkit-transform: perspective(1000px) rotate3d(0,1,0,360deg);
	transform: perspective(1000px) rotate3d(0,1,0,360deg);
}

.style_links span:nth-child(4),
.style_links:hover span:first-child {
	-webkit-transition-delay: 0s;
	transition-delay: 0s;
}

.style_links span:nth-child(3),
.style_links:hover span:nth-child(2) {
	-webkit-transition-delay: 0.1s;
	transition-delay: 0.1s;
}

.style_links span:nth-child(2),
.style_links:hover span:nth-child(3) {
	-webkit-transition-delay: 0.2s;
	transition-delay: 0.2s;
}

.style_links span:first-child,
.style_links:hover span:nth-child(4) {
	-webkit-transition-delay: 0.3s;
	transition-delay: 0.3s;
}
 
.line {
	margin-top: 5%;
	height: 1px;
	background: rgb(116, 3, 3);
  }

  .fa-github{
	  color:#fff;
	  font-size: 15px;
  }

  .fa-home{
	font-size: 15px;
  }
  
  .fa-cogs{
	font-size: 15px;
  }

  .fa-sign-out{
	font-size: 15px;
  }

  .fa-lock{
	font-size: 30px;
  }

  .fa-ellipsis-h{
	  font-size: 25px;
  }

  .fa-image {
	font-size: 30px;
	float: right;
	margin-right: 5px;
  }

  .fa-image:hover {
	font-size: 33px;
	cursor: default;
  }

  @media screen and (max-width:540px) {

	  .fa-home{
		margin-top: -10px;
		font-size: 30px;
	  }
	 
	  .fa-github{
		margin-top: -16px;
		font-size: 27px;
	  }

	  .fa-cogs{
		margin-top: -10px;
		font-size: 27px;
	  }
	 
	  .fa-sign-out{
		margin-top: -10px;
		font-size: 27px;
	  }

	  .file-title{
		  font-size: 0;
	  }
	  .fa-image {
		font-size: 25px;
		float: left;
		margin-left: 5px;
		margin-top: 3px;
	  }


	  .fa-image:hover {
		font-size: 27px;
		cursor: default;
	  }

	}




/*----------------------------------------------------------------------------Project-----------------------------------------------------------------*/
  .general-wrapper{
	  overflow-y: scroll;
	  overflow-x: hidden;
	  height: 82%;
	  width: 50%;
	  position: fixed;
	  top: 13%;
	  left: 50%;
	  text-align: center;
	  transform: translate(-50%, 5%);
  }
  @media screen and (max-width:540px) {
	.general-wrapper{
		width: 80%;
		height: 80%;
	}
}
 
  .project-wrapper{
	width: 100%;
	height: 100%;
  }

  ::-webkit-scrollbar{
	  width: 0;
  }
 
  .project-summary{
	margin-top: 20px;
	background-color:black;
	border-style: solid;
	border-color: #050505;
	border-radius: 25px;
	opacity: 1;
}

@media screen and (max-width:540px) {
	.project-summary{
		width: 100%;
	}
}



  .project-title{
	color:#fff;
	font-weight: bold;
	margin-left: 10px;
	font-size: 100%;
}


.project-description{
	color: white;
	width: 80%;
	position: relative;
	left: 50%;
	transform: translateX(-50%);
	text-align: center;
	margin-top: 10px;
	font-family: Cyber;
}

.project-button{
	margin-top: 10px;
}


.mangekyou {
	background: #050505;
	width: 4em;
	height: 4em;
	left: 50%;
	transform: translateX(-50%);
	position: relative;
	border: 2px #000 solid;
	animation: spin 5s ease-in-out;
	margin-bottom: 10px;

  }

	 
.mangekyou .point {
	position: absolute;
	top: 50%;
	left: 50%;
	height: 2.8em;
	width: 2.8em;
	background: rgb(116, 3, 3);
	animation: shine 5s ease-in-out;
  }

.mangekyou .point:nth-child(1) {
	border-radius: 0% 100% 0% 100% / 0% 100% 0% 100%;
	transform: translate(-50%, -50%) rotate(-15deg);
  }

  .mangekyou .point:nth-child(1){
	border-radius: 0% 100% 0% 100% / 0% 100% 0% 100%;
	transform: translate(-50%, -50%) rotate(-15deg);
  }
  
.mangekyou .point:nth-child(2) {
	border-radius: 100% 0% 100% 0% / 100% 0% 100% 0%;
	transform: translate(-50%, -50%) rotate(15deg);
  }
  
.mangekyou .point:nth-child(3) {
	border-radius: 100% 0% 100% 0% / 100% 0% 100% 0%;
	transform: translate(-50%, -50%) rotate(-45deg);
  	border: 2px #000 solid;
  }

.mangekyou .point:nth-child(3)::before,
.mangekyou .point:nth-child(3)::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  border: inherit;
  width: 100%;
  height: 100%;
  border-radius: inherit;

}

.mangekyou .point:nth-child(3)::before {
	transform: translate(-50%, -50%) rotate(-60deg);
  }
  
.mangekyou .point:nth-child(3)::after {
	transform: translate(-50%, -50%) rotate(60deg);
  }

.mangekyou, .circle{
  border-radius: 50%;
}

.circle {
	position: absolute;
	width: 0.5em;
	height: 0.5em;
	background: #000;
	z-index: 2;
  }

  .mangekyou .circle {
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 1em;
	height: 1em;
	box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.6);
  }

  @keyframes spin {
	0% {
	  
	  transform: translateX(-50%) rotate(0deg);
	}
   
	100% {	
	  transform: translateX(-50%) rotate(720deg);
	}
  }
  
  @keyframes shine {
	0% {
	  background: #e41414;
	  opacity: 0;
	}
	50% {
	  background: #f03a3a;
	  box-shadow: 0 0 0 0 #f03a3a;
	}
	100% {
	  box-shadow: none;
	  background: rgb(116, 3, 3);
	  opacity: 1;
	}
  }

  .show-project-preview{
	  height: 100%;
  }


  textarea{
	  font-size: 90%;
	  width: 98%;
	  height: 90%;
	  color:#fff;
	  background-color: #000;
	  margin-top: 10px; 
	  border-radius: 15px;
	  border: none;
      resize: none;
      outline: none;
	  /*border-color: rgb(116, 3, 3);*/
	  cursor: default;
	  opacity: 1;
  }
  
  @media screen and (max-width:540px) {
	textarea{
		opacity: 1;
	}
	.show-project-preview .title .file-title{ 
		font-size: 20px;
	}
}
 
.container_img{

	position: fixed;
	height: 82%;
    width: 50%;
	top: 10%;
	left: 50%;
	text-align: center;
	transform: translate(-50%, 5%);
}

@media screen and (max-width:1150px) {

	.container_img{
		width: 70%;
	}
}

@media screen and (max-width:799px) {

	.container_img{
		width: 90%;
	}
}

@media screen and (max-width:620px) {

	.container_img{
		width: 100%;
	}
}


img {
	image-rendering:smooth;
	position: fixed;
	margin-top: 10px;
	border-radius: 15px;
	width: 100%;
	height: 90%;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

@media screen and (max-width:540px) {

	img {
		width: 100%;
		height:68vh;
		image-rendering:pixelated;
}

}

  
  .btn {
	width: 30%;
	height: 40px; 
	border: 0;
	outline: none;
	background-color:#050505;
	cursor: pointer;
	position: relative;
	font-family: Cyber;
	font-size: 20px;
	color: white;
	clip-path: polygon(92% 0, 100% 25%, 100% 100%, 8% 100%, 0% 75%, 0 0);
	margin-top: 10px;
	margin-bottom: 5px;
  }
  @media screen and (max-width:540px) {
	.btn{
		width: 40%;
		height: 40px; 
	}
}

@media screen and (max-width:375px) {
	.btn{
		width: 100%;
		height: 40px; 
	}
}


@media screen and (min-width:540px) and (max-width:813px) {
	.btn{
		width: 50%;
		height: 40px; 
	}
}


  
  .btn__content {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 2px;
	left: 2px;
	right: 2px;
	bottom: 2px;
	background-color: #050505;
	clip-path: polygon(92% 0, 100% 25%, 100% 100%, 8% 100%, 0% 75%, 0 0);
  }

  .btn__glitch {
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #050505;
	/*filter: drop-shadow(-2px 3px rgb(116, 3, 3)) drop-shadow(-1px -3px rgb(116, 3, 3)) drop-shadow(2px 1px rgb(116, 3, 3));*/
  }

  .btn .btn__glitch,
	.btn .btn__content::after,
	.btn .btn__glitch,
	.btn .btn__content::after {
  display: block;
  animation: glitch-animation 3s linear 0s infinite;
}

@keyframes glitch-animation {
	0% {
	  opacity: 1;
	  transform: translateZ(0);
	  clip-path: polygon(0 2%, 100% 2%, 100% 5%, 0 5%);
	}
  
	2% {
	  clip-path: polygon(0 78%, 100% 78%, 100% 100%, 0 100%);
	  transform: translate(-5px);
	}
  
	6% {
	  clip-path: polygon(0 78%, 100% 78%, 100% 100%, 0 100%);
	  transform: translate(5px);
	}
  
	8% {
	  clip-path: polygon(0 78%, 100% 78%, 100% 100%, 0 100%);
	  transform: translate(-5px);
	}
  
	9% {
	  clip-path: polygon(0 78%, 100% 78%, 100% 100%, 0 100%);
	  transform: translate(0);
	}
  
	10% {
	  clip-path: polygon(0 54%, 100% 54%, 100% 44%, 0 44%);
	  transform: translate3d(5px, 0, 0);
	}
  
	13% {
	  clip-path: polygon(0 54%, 100% 54%, 100% 44%, 0 44%);
	  transform: translateZ(0);
	}
  
	13.1% {
	  clip-path: polygon(0 0, 0 0, 0 0, 0 0);
	  transform: translate3d(5px, 0, 0);
	}
  
	15% {
	  clip-path: polygon(0 60%, 100% 60%, 100% 40%, 0 40%);
	  transform: translate3d(5px, 0, 0);
	}
  
	20% {
	  clip-path: polygon(0 60%, 100% 60%, 100% 40%, 0 40%);
	  transform: translate3d(-5px, 0, 0);
	}
  
	20.1% {
	  clip-path: polygon(0 0, 0 0, 0 0, 0 0);
	  transform: translate3d(5px, 0, 0);
	}
  
	25% {
	  clip-path: polygon(0 85%, 100% 85%, 100% 40%, 0 40%);
	  transform: translate3d(5px, 0, 0);
	}
  
	30% {
	  clip-path: polygon(0 85%, 100% 85%, 100% 40%, 0 40%);
	  transform: translate3d(-5px, 0, 0);
	}
  
	30.1% {
	  clip-path: polygon(0 0, 0 0, 0 0, 0 0);
	}
  
	35% {
	  clip-path: polygon(0 63%, 100% 63%, 100% 80%, 0 80%);
	  transform: translate(-5px);
	}
  
	40% {
	  clip-path: polygon(0 63%, 100% 63%, 100% 80%, 0 80%);
	  transform: translate(5px);
	}
  
	45% {
	  clip-path: polygon(0 63%, 100% 63%, 100% 80%, 0 80%);
	  transform: translate(-5px);
	}
  
	50% {
	  clip-path: polygon(0 63%, 100% 63%, 100% 80%, 0 80%);
	  transform: translate(0);
	}
  
	55% {
	  clip-path: polygon(0 10%, 100% 10%, 100% 0, 0 0);
	  transform: translate3d(5px, 0, 0);
	}
  
	60% {
	  clip-path: polygon(0 10%, 100% 10%, 100% 0, 0 0);
	  transform: translateZ(0);
	  opacity: 1;
	}
  
	60.1% {
	  clip-path: polygon(0 0, 0 0, 0 0, 0 0);
	  opacity: 1;
	}
  
	to {
	  clip-path: polygon(0 0, 0 0, 0 0, 0 0);
	  opacity: 1;
	}
  }

.title{
	color:#fff;
	font-family:Cyber;
	font-size: 25px;
	width: 100%;
}

.file-title{
float: left;
margin-left: 10px;
}

.fa-times-circle{
	  float: left;
	  color: rgb(116, 3, 3);
	  font-size: 25px;
	  margin-left: 10px;
	}
	@media screen and (max-width:540px) {
		.fa-times-circle{
			float: right;
		}
	}


.fa-times-circle:hover{
	font-size: 28px;
	}	  